import React, { useEffect, useRef, useState } from "react";
import ProfileDetailsTab from "./ProfileDetailsTab";
import ContactDetailsTab from "./ContactDetailsTab";
import BankDetailsTab from "./BankDetailsTab";
import PaymentDetailsTab from "./PaymentDetailsTab";
// import SettingsTab from "./SettingsTab";
import { useTranslation } from "react-i18next";
import AdditionalDetails from "./AdditionalDetailsTab";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const ProfileLayout = ({ profile }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("firstTab");

  const showBankDetails = useSelector((state) => state.user.profile.bankDetails)
  const containerRef = useRef(null);
  

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(()=> {
    if (location.pathname === '/profile/payment-details') {
      handleTabClick("fourthTab");
    }
  }, [location.pathname])

  return (
    <div className="profileTabSec">
      <div className="profileTabBg">
        {/* <div className="tab">
          <button
            className={`tablinks ${activeTab === "firstTab" ? "active" : ""}`}
            onClick={() => handleTabClick("firstTab")}
          >
            {t("personalDetails")}
          </button>
          <button
            className={`tablinks ${activeTab === "secondTab" ? "active" : ""}`}
            onClick={() => handleTabClick("secondTab")}
          >
            {t("contactDetails")}
          </button>
          {showBankDetails &&
            <button
              className={`tablinks ${activeTab === "thirdTab" ? "active" : ""}`}
              onClick={() => handleTabClick("thirdTab")}
            >
              {t("bankDetails")}
            </button>
          }
          <button
            className={`tablinks ${activeTab === "fourthTab" ? "active" : ""}`}
            onClick={() => handleTabClick("fourthTab")}
          >
            {t("paymentDetails")}
          </button>
          {profile?.additionalDetails?.length > 0 && (
            <button
              className={`tablinks ${activeTab === "fifthTab" ? "active" : ""}`}
              onClick={() => handleTabClick("fifthTab")}
            >
              {t("additionalDetails")}
            </button>
          )} */}
          {/* <button
            className={`tablinks ${activeTab === "fifthTab" ? "active" : ""}`}
            onClick={() => handleTabClick("fifthTab")}
          >
            {t("settings")}
          </button> */}
        {/* </div> */}
        <div ref={containerRef}></div>
        {activeTab === "firstTab" && <ProfileDetailsTab />}
        {activeTab === "secondTab" && profile?.contactDetails && (
          <ContactDetailsTab
            contact={profile?.contactDetails}
            countries={profile?.countries}
          />
        )}
        {activeTab === "thirdTab" && profile?.bankDetails && (
          <BankDetailsTab bank={profile?.bankDetails} />
        )}
        {activeTab === "fourthTab" && profile?.payoutDetails && (
          <PaymentDetailsTab payment={profile?.payoutDetails} />
        )}
        {activeTab === "fifthTab" && profile?.additionalDetails && (
          <AdditionalDetails additional={profile?.additionalDetails} />
        )}
      </div>
    </div>
  );
};

export default ProfileLayout;